import classNames from 'classnames';
import { ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import avCorpLogo from '../../assets/img/logo192.png';
import Collapse from '../../components/bootstrap/Collapse';
import Icon from '../../components/icon/Icon';
import AuthContext from '../../contexts/authContext';
import { confirmAction } from '../../helpers/confirmAction';
import useDarkMode from '../../hooks/useDarkMode';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import { profilePage } from '../../menu';
import ApiWeb from '../../services/api/ApiWeb';
import { NavigationLine } from '../Navigation/Navigation';

const User = () => {
	const { userData } = useContext(AuthContext);

	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);
	const { t } = useTranslation(['registration', 'menu']);

	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-avatar'>
					<img
						srcSet={`${avCorpLogo} 1x, ${avCorpLogo} 2x`}
						src={avCorpLogo}
						alt='Avatar'
						width={128}
						height={128}
					/>
				</div>
				<div className='user-info'>
					<div className='user-name d-flex align-items-center'>
						{userData?.name}
						<Icon icon='Verified' className='ms-1' color='info' />
					</div>
					<div className='user-sub-title'>
						{userData.roles && userData.roles.length > 0
							? t(`registration:user_roles:${userData.roles[0].id}`)
							: 'No Role'}
					</div>
				</div>
			</div>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`../${profilePage.profile.path}`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Profile') as ReactNode}
									</span>
								</span>
							</span>
						</div>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{darkModeStatus
											? (t('menu:DarkMode') as ReactNode)
											: (t('menu:LightMode') as ReactNode)}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
				<NavigationLine />
				<nav
					aria-label='aside-bottom-user-menu-2'
					onClick={async () => {
						await confirmAction(
							t('registration:messages:confirm_exit'),
							async () => {
								await ApiWeb.postadminLogout();
							},
							true,
						);
					}}>
					<div className='navigation'>
						<div role='presentation' className='navigation-item cursor-pointer'>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />

									<span className='navigation-text'>
										{t('menu:Logout') as ReactNode}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
