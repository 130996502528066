// utils.ts
import Swal from 'sweetalert2';

// A função service é opcional. Se não for fornecida, a função não será chamada.
// confirmationMessage é um parâmetro obrigatório e vem primeiro.
export async function confirmAction(
	confirmationMessage: string,
	service?: (...args: any[]) => Promise<any>,
	skipLoadingAndSuccessDisplay: boolean = false,
): Promise<any | null> {
	const confirmationResult = await Swal.fire({
		title: 'Confirmação',
		text: confirmationMessage,
		icon: 'question',
		showCancelButton: true,
		confirmButtonText: 'Sim',
		cancelButtonText: 'Não',
	});

	if (confirmationResult.isConfirmed) {
		try {
			Swal.fire({
				title: 'Processando...',
				text: 'Por favor, aguarde.',
				icon: 'info',
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			// Se um serviço foi fornecido, chama ele.
			const result = service ? await service() : null;
			if (!skipLoadingAndSuccessDisplay) {
				// Se o serviço for bem-sucedido, mostra uma mensagem de sucesso.
				await Swal.fire({
					title: 'Sucesso!',
					text: 'Ação Realizada Com Sucesso!',
					icon: 'success',
					confirmButtonText: 'Ok',
				});
			}
			return result;
		} catch (error) {
			// Se ocorrer um erro, mostra uma mensagem de erro.
			await Swal.fire({
				title: 'Erro!',
				text: `Ocorreu um Erro: ${
					error instanceof Error ? error.message : 'Unknown error'
				}`,
				icon: 'error',
				confirmButtonText: 'Ok',
			});

			// Retorna null ou lança o erro.
			return null;
		}
	} else {
		// Se não for confirmado, apenas retorna null.
		return null;
	}
}
