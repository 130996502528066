import React, { SVGProps } from 'react';

const SvgCustomBrazil = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 842.12 842.12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="svg-icon"
    {...props}
  >
    <path
      fill="#3D9934"
      d="M659.2,842.12H182.92C81.9,842.12,0,760.22,0,659.2V182.92C0,81.9,81.9,0,182.92,0H659.2C760.22,0,842.12,81.9,842.12,182.92V659.2C842.12,760.22,760.22,842.12,659.2,842.12z"
    />
    <polygon
      fill="#F9DD00"
      points="63.53,425.55 423.25,193.11 784.67,424.99 424.01,659.39"
    />
    <ellipse
      fill="#112772"
      cx="424.4"
      cy="424.87"
      rx="152.39"
      ry="154.26"
    />
    <path
      fill="#FFFFFF"
      d="M410.3,364.18c104.22,22.2,164.77,83.91,164.77,83.91c-1.61,12.02-5.63,24.44-5.63,24.44c-67-64.15-150.69-81.46-150.69-81.46c-74.54-18.18-141.84-5.09-141.84-5.09c2.11-8.71,8.15-23.68,8.15-23.68S335.85,352.77,410.3,364.18z"
    />
  </svg>
);

export default SvgCustomBrazil;
