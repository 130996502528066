import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import Icon from '../../../components/icon/Icon';
import AuthContext from '../../../contexts/authContext';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { appPagesMenu } from '../../../menu';
import CommonSupportOffCanvas from '../../_common/CommonSupportOffCanvas';
import getAppPagesAvailable from './util/GetAppPagesAvailable';
import rolesPagesAllowed from './util/RolesPagesAllowed';
import { useTranslation } from 'react-i18next';

const DefaultAside = () => {
	const { t } = useTranslation('menu');
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { userData } = useContext(AuthContext);

	const userRoles = userData.roles?.map((role) => role.pivot.user_role_id);
	const pagesAvailable = getAppPagesAvailable(userRoles, appPagesMenu, rolesPagesAllowed);

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const handleUpcomingSupport = () => {
		setOffcanvasStatus(!offcanvasStatus);
	};
	const [storedIsMobile, setStoredIsMobile] = useState(false);

	useLayoutEffect(() => {
		const handleResize = () => {
			// Retrieve 'isMobile' from localStorage
			const storedValue = localStorage.getItem('isMobile');
			setStoredIsMobile(storedValue === 'true');
		};

		// Initial check
		handleResize();

		// Add event listener to check dimensions on window resize
		window.addEventListener('resize', handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	if (storedIsMobile === null) {
		// Optionally return a loading state or nothing while waiting for the value
		return null;
	}

	return (
		<>
			{storedIsMobile ? (
				<div></div>
			) : (
				<Aside>
					<AsideHead>
						<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
					</AsideHead>
					<AsideBody>
						<Navigation menu={pagesAvailable} id='aside-app-pages' />
					</AsideBody>
					<AsideFoot>
						<User />
						<NavigationLine />
						<nav aria-label='aside-bottom-menu'>
							<div className='navigation'>
								<div
									role='presentation'
									className='navigation-item cursor-pointer'
									onClick={() => handleUpcomingSupport()}
									data-tour='documentation'>
									<span className='navigation-link navigation-link-pill'>
										<span className='navigation-link-info'>
											<Icon
												icon='Support Agent'
												color='success'
												className='navigation-icon'
											/>
											<span className='navigation-text'>
												{t('general:need_help:support')}
											</span>
										</span>
									</span>
								</div>
							</div>
						</nav>
					</AsideFoot>
					<CommonSupportOffCanvas setOpen={setOffcanvasStatus} isOpen={offcanvasStatus} />
				</Aside>
			)}
		</>
	);
};

export default DefaultAside;
