import React, { useContext } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ThemeContext from '../../../contexts/themeContext';

const DashboardHeader = () => {
	const { userData } = useContext(AuthContext);
	const { darkModeStatus } = useDarkMode();
	const { t } = useTranslation(['general']);
	const { mobileDesign } = useContext(ThemeContext);

	return (
		<Header>
			<HeaderLeft>
				<div className='d-flex align-items-center'>
					<div className='row g-4'>
						<div className='col-md-auto'>
							<div
								className={classNames('fs-3', 'fw-bold', {
									'text-dark': !darkModeStatus,
								})}>
								{t('general:greeting')}
								{', '}
								{userData.name}
								{'!'}
							</div>
						</div>
					</div>
				</div>
			</HeaderLeft>
			{!mobileDesign && <CommonHeaderRight />}
		</Header>
	);
};

export default DashboardHeader;
