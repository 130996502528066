import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu } from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';

const headers: RouteProps[] = [
	{ path: dashboardPagesMenu.dashboard.path, element: <DashboardHeader /> },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
