import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRoleBasedRoutes } from '../../helpers/GetAppRolesContent';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));

const ContentRoutes = () => {
	const roleBasedRoutes = useRoleBasedRoutes(); // Call the hook here

	return (
		<Routes>
			{roleBasedRoutes.map((page) => (
				<Route key={page.path} path={page.path} element={page.element} />
			))}
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
