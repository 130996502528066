import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<svg
			width={height !== 854 && !!height ? height * (2155 / 854) : width}
			height={width !== 2155 && !!width ? width * (854 / 2155) : height}
			viewBox='-100 -100 2155 854'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			{' '}
			<style type='text/css'>
				{'\n\t.st0{fill:url(#SVGID_1_);}\n\t.st1{fill:#FFFFFF;}\n'}
			</style>
			<g>
				<linearGradient
					id='SVGID_1_'
					gradientUnits='userSpaceOnUse'
					x1={49.9418}
					y1={217.9794}
					x2={642.6129}
					y2={217.9794}>
					<stop
						offset={0}
						style={{
							stopColor: '#FF4D00',
						}}
					/>
					<stop
						offset={0.5868}
						style={{
							stopColor: '#FF6A00',
						}}
					/>
					<stop
						offset={1}
						style={{
							stopColor: '#FF8100',
						}}
					/>
				</linearGradient>
				<path
					className='st0'
					d='M49.94,393.75L225.73,42.21L401.5,393.75h-58.59L225.73,159.37L108.54,393.75H49.94z M466.84,393.75 L291.05,42.21h58.59l117.2,234.37L584.01,42.21h58.6L466.84,393.75z'
				/>
				<g>
					<g>
						<path
							className='st1'
							d='M789.11,393.93c-20.88-1.98-40.27-8.01-58.18-18.16c-17.89-10.08-33.79-22.93-47.71-38.51 c-7.65-8.29-14.17-17.41-19.64-27.34c-5.48-9.95-10.21-20.23-14.18-30.85c-7.3-19.88-10.78-40.01-10.44-60.41 c0.34-20.39,3.64-39.85,9.96-58.42c6.3-18.57,15.32-35.79,27.1-51.7c11.76-15.92,25.59-29.27,41.51-40.05 c15.92-10.75,33.56-18.45,52.96-23.12c19.39-4.63,40.03-5.28,61.91-1.99c25.85,4,49.21,14.11,70.11,30.34 c20.88,16.26,37.45,35.8,49.72,58.67c0.64,1,1.8,3.32,3.46,6.97c1.65,3.65,2.82,6.28,3.48,7.94c0,0.67,0.32,1.66,1,2.99 c0.67,1.33,1,2.32,1,2.99c-1.68,0.67-3.5,1.66-5.48,2.98c-1.99,1.33-3.82,1.66-5.47,1c-5.3-12.6-11.36-24.12-18.15-34.56 c-6.78-10.45-15.16-20.46-25.1-30.08c-18.57-17.24-40.01-28.74-64.4-34.57c-24.37-5.79-48.8-5.72-73.34,0.25 c-19.89,4.99-38.22,14.11-54.95,27.34c-16.74,13.26-30.07,28.84-40,46.75c-10.29,18.59-17.01,37.8-20.15,57.68 c-3.16,19.9-2.98,39.3,0.49,58.18c3.48,18.92,9.86,36.72,19.15,53.45c9.27,16.75,21.05,31.23,35.3,43.5 c14.25,12.28,30.67,21.81,49.23,28.59c18.56,6.82,38.78,9.55,60.66,8.21c14.91-0.65,29.33-3.97,43.26-9.92 c13.93-5.99,26.76-13.86,38.53-23.63c11.78-9.77,21.97-20.95,30.59-33.56c8.62-12.61,15.23-25.86,19.9-39.78l10.92,3.97v1.02 c-2.66,7.93-5.88,15.67-9.69,23.09c-3.83,7.48-8.03,14.67-12.68,21.67c-8.62,12.24-18.57,23.01-29.84,32.3 c-11.27,9.29-23.45,16.81-36.55,22.61c-13.1,5.81-26.76,9.96-41.01,12.43C818.11,394.66,803.7,395.25,789.11,393.93z'
						/>
						<path
							className='st1'
							d='M1363.43,189.06c-2.99-16.55-7.81-32.48-14.42-47.71c-6.64-15.24-15.77-29.18-27.36-41.76 c-17.57-19.23-36.37-33.83-56.43-43.77c-20.07-9.94-40.28-15.84-60.66-17.63c-20.4-1.82-40.53-0.18-60.41,4.96 c-19.91,5.15-38.3,13.25-55.2,24.37c-16.9,11.1-31.75,24.69-44.49,40.76c-12.76,16.07-22.48,33.9-29.09,53.44 c-6.64,19.57-9.53,40.54-8.71,62.9c0.84,22.39,6.54,45.5,17.16,69.36c5.29,12.26,12.35,23.87,21.13,34.81 c8.79,10.94,18.64,20.72,29.59,29.35c10.94,8.61,22.7,15.97,35.29,22.1c12.61,6.16,25.53,10.38,38.8,12.69 c20.54,4,40.58,4.56,60.16,1.72c19.54-2.8,37.85-8.17,54.92-16.14c17.09-7.95,32.58-18.39,46.51-31.32 c13.92-12.92,25.42-27.6,34.54-44.02c9.12-16.4,15.42-34.23,18.92-53.44C1367.13,230.51,1367.07,210.31,1363.43,189.06z  M1347.25,260.18c-4.81,19.23-13.35,37.48-25.61,54.7c-8.95,12.93-19.56,24.21-31.81,33.8c-12.27,9.64-25.62,17.33-40.03,23.14 c-14.42,5.79-29.51,9.6-45.24,11.42c-15.75,1.84-31.43,1.42-46.99-1.24c-21.88-3.97-42.01-11.67-60.43-23.12 c-18.39-11.43-33.87-26.1-46.49-43.99c-12.6-16.9-21.12-34.88-25.61-53.95c-4.48-19.06-6.21-38.7-5.21-58.93 c0.31-13.26,2.9-26.25,7.71-39.03c4.8-12.75,11.02-24.76,18.65-36.05c7.62-11.24,16.4-21.62,26.35-31.07 c9.93-9.44,20.37-17.31,31.31-23.61c14.92-8.95,31.01-15.17,48.22-18.66c17.24-3.46,34.47-4.21,51.72-2.22 c21.21,2.31,40.59,8.04,58.19,17.13c17.55,9.14,32.72,20.58,45.48,34.32c12.76,13.76,23.01,29.25,30.82,46.51 c7.78,17.22,12.45,35.2,13.93,53.94C1353.73,221.98,1352.07,240.95,1347.25,260.18z'
						/>
						<path
							className='st1'
							d='M1563.79,300.96h-1c-5.95,0.99-12.01,2.06-18.14,3.22c-6.14,1.19-12.34,1.75-18.65,1.75v-11.94 c20.89-1.31,39.86-6.62,56.94-15.91c17.08-9.26,30.91-21.38,41.52-36.29c10.61-14.92,17.31-31.9,20.13-50.98 c2.8-19.04,0.57-38.85-6.72-59.41c-6.61-17.56-16.98-32.89-31.07-45.98c-14.08-13.11-30.07-22.13-47.98-27.12 c-1,0-3.16-0.5-6.48-1.48c-3.32-1.01-5.46-1.51-6.46-1.51c-0.67,0-1.82-0.16-3.47-0.49c-1.67-0.34-2.84-0.49-3.48-0.49h-106.91 v338.61h-12.94V41.41h112.88c12.93,0,25.78,2.41,38.54,7.2c12.75,4.8,24.11,11.02,34.05,18.64 c14.92,10.94,26.78,24.28,35.55,40.04c8.79,15.73,14.25,32.3,16.4,49.72c2.16,17.39,0.92,34.9-3.72,52.47 c-4.65,17.54-12.93,33.46-24.86,47.72c-6.97,8.61-14.75,15.99-23.37,22.12c-8.62,6.13-17.89,11.68-27.83,16.67v1l72.09,95.95 h-14.92L1563.79,300.96z'
						/>
						<path
							className='st1'
							d='M1824.34,41.41c12.59,0,24.86,2.22,36.8,6.7c11.93,4.47,22.87,10.19,32.81,17.16 c14.25,9.6,25.69,20.96,34.31,34.06c8.6,13.09,14.67,26.92,18.14,41.5c3.47,14.59,4.48,29.43,2.99,44.51 c-1.5,15.08-5.39,29.5-11.7,43.26c-6.29,13.76-14.83,26.35-25.6,37.8c-10.76,11.42-23.78,20.63-39.03,27.58 c-7.97,4-16.82,7.23-26.62,9.71c-9.77,2.46-19.13,3.22-28.08,2.23v-11.94c24.86-1.63,46.66-8.6,65.38-20.86 c18.73-12.27,33.56-29.83,44.5-52.72c4.65-9.27,7.45-18.98,8.45-29.1c0.98-10.09,1.15-20.44,0.48-31.06 c-0.65-12.26-4.22-24.68-10.67-37.29c-6.48-12.6-14.75-24.04-24.87-34.31c-10.11-10.28-21.48-18.57-34.05-24.86 c-12.63-6.29-25.02-9.45-37.3-9.45h-105.9v338.61h-12.94V41.41H1824.34z'
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
