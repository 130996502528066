export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		color: 'grey',
	},
};

export const appPagesMenu = {
	customerServices: {
		id: 'customerServices',
		text: 'Servicos',
		path: 'services',
		icon: 'Category',
		color: 'warning',
		subMenu: null,
	},
	billing: {
		id: 'billing',
		text: 'Billing',
		path: 'billing',
		icon: 'Payments',
		color: 'danger',
		subMenu: null,
	},
	reports: {
		id: 'reports',
		text: 'Reports',
		path: 'reports',
		icon: 'Leaderboard',
		color: 'orange',
		subMenu: null,
	},
	registry: {
		id: 'registry',
		text: 'Registry',
		path: 'registry',
		icon: 'Group',
		color: 'navy',
		subMenu: null,
	},
};

export const profilePage = {
	profile: {
		id: 'profile',
		text: 'Profile',
		path: 'profile',
		icon: 'User',
		subMenu: null,
	},
};
