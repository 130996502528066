import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import avCorpLogo from '../../../assets/img/logo192.png';
import Avatar from '../../../components/Avatar';
import AuthContext from '../../../contexts/authContext';
import useNavigationItemHandle from '../../../hooks/useNavigationItemHandle';
import { profilePage } from '../../../menu';

const CommonHeaderUser = () => {
	const { t } = useTranslation(['general', 'registration', 'notifications']);
	const { userData } = useContext(AuthContext);
	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();

	return (
		<div
			className='col d-flex align-items-center cursor-pointer'
			onClick={() =>
				navigate(
					`../${profilePage.profile.path}`,
					// @ts-ignore
					handleItem(),
				)
			}
			role='presentation'>
			<div className='me-3'>
				<div className='text-end'>
					<div className='fw-bold fs-6 mb-0'>{userData.name}</div>
					<div className='text-muted'>
						<small>
							{userData.roles && userData.roles.length > 0
								? t(`registration:user_roles:${userData.roles[0].id}`)
								: 'No Role'}
						</small>
					</div>
				</div>
			</div>
			<div className='position-relative'>
				<Avatar srcSet={`${avCorpLogo} 1x, ${avCorpLogo} 2x`} src={avCorpLogo} size={48} />
			</div>
		</div>
	);
};

export default CommonHeaderUser;
