import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import WhatsAppIconWhite from '../../assets/img/custom-whatsapp-white.svg';
import Button from '../../components/bootstrap/Button';
import { CardLabel, CardTitle } from '../../components/bootstrap/Card';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../components/bootstrap/OffCanvas';
import Icon from '../../components/icon/Icon';

interface ICommonSupportOffCanvas {
	isOpen: boolean;
	setOpen(...args: unknown[]): unknown;
}

const CommonSupportOffCanvas: FC<ICommonSupportOffCanvas> = ({ isOpen, setOpen }) => {
	const { t } = useTranslation(['general']);

	const handleClick = () => {
		window.open('https://wa.me/553130033633', '_blank');
	};

	return (
		<OffCanvas isOpen={isOpen} setOpen={setOpen}>
			<OffCanvasHeader setOpen={setOpen} className='p-4'>
				<OffCanvasTitle id='registryEdit' tag='h3' className='mb-4 fw-bold'>
					{t('general:need_help:support')}
				</OffCanvasTitle>
			</OffCanvasHeader>
			<OffCanvasBody className='px-4'>
				<div className='row g-4'>
					<CardLabel icon='Schedule' iconColor={'warning'} tag={'div'}>
						<CardTitle tag='div' className='h3'>
							{t('general:need_help:working_hours')}
						</CardTitle>
					</CardLabel>
					<div className='col-12 h5 mx-3'>{t('general:need_help:mon_tue')}</div>
					<div className='col-12 h5 mx-3'>{t('general:need_help:fri')}</div>
					<CardLabel icon='Campaign' iconColor={'info'} tag={'div'} className='mt-5'>
						<CardTitle tag='div' className='h4'>
							{t('general:need_help:channels')}
						</CardTitle>
					</CardLabel>
					<p className='col-12 h6 mx-3 d-flex align-items-center'>
						<Icon icon='email' className='me-2' />
						{t('general:need_help:commercial')}
						{': '}
						<a href='mailto:atendimento@avcorp.com.br' className='ms-1'>
							atendimento@avcorp.com.br
						</a>
					</p>
					<p className='col-12 h6 mx-3 d-flex align-items-center'>
						<Icon icon='email' className='me-2' />
						{t('general:need_help:financial')}
						{': '}
						<a href='mailto:atendimento@avcorp.com.br' className='ms-1'>
							cob@avcorp.com.br
						</a>
					</p>
					<p className='col-12 h6 mx-3 d-flex align-items-center'>
						<Icon icon='email' className='me-2' />
						{t('general:need_help:technical_support')}
						{': '}
						<a href='mailto:atendimento@avcorp.com.br' className='ms-1'>
							suporte@avcorp.com.br
						</a>
					</p>
					<CardLabel icon='Phone' iconColor={'primary'} tag={'div'} className='mt-5'>
						<CardTitle tag='div' className='h4'>
							{t('general:need_help:phones')}
						</CardTitle>
					</CardLabel>

					<p className='col-12 h3 mx-3 d-flex align-items-center'>
						<Icon icon='Dialpad' className='me-2' />
						<a href='tel:30033633' className='ms-1 text-decoration-none text-dark h3'>
							3003-3633
						</a>
					</p>
					<p className='col-12 h3 mx-3 d-flex align-items-center'>
						<Icon icon='Dialpad' className='me-2' />
						<a href='tel:40038433' className='ms-1 text-decoration-none text-dark h3'>
							4003-8433
						</a>
					</p>
					<CardLabel icon='Public' iconColor={'orange'} tag={'div'} className='mt-5'>
						<CardTitle tag='div' className='h5'>
							<a
								href='https://www.avcorp.com.br'
								target='_blank'
								rel='noreferrer'
								className='ms-1'>
								www.avcorp.com.br
							</a>
						</CardTitle>
					</CardLabel>
					<div className='col-12 h3' style={{ marginTop: '6rem' }}>
						<Button color='success' onClick={handleClick}>
							<img
								src={WhatsAppIconWhite}
								alt='WhatsApp Icon'
								style={{
									margin: '10px',
									width: '24px',
									height: '24px',
									cursor: 'pointer',
								}}
							/>
							{t('general:need_help:wapp_subtitle')}
						</Button>
					</div>
				</div>
			</OffCanvasBody>
		</OffCanvas>
	);
};

export default CommonSupportOffCanvas;
