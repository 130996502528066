import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FC, ReactNode, useContext, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Popovers from '../../../components/bootstrap/Popovers';
import Spinner from '../../../components/bootstrap/Spinner';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import useDarkMode from '../../../hooks/useDarkMode';
import LANG, { ILang, getLangWithKey } from '../../../lang';
import { HeaderRight } from '../../../layout/Header/Header';
import CommonHeaderUser from './CommonHeaderUser';
import CommonSupportOffCanvas from '../../_common/CommonSupportOffCanvas';

interface ICommonHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({ beforeChildren, afterChildren }) => {
	const { t } = useTranslation(['general']);
	const { mobileDesign } = useContext(ThemeContext);
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const { i18n } = useTranslation();

	const changeLanguage = (lng: ILang['key']['lng']) => {
		i18n.changeLanguage(lng).then(() => {
			const langText = getLangWithKey(lng)?.text;
			const languageText = i18n.t('menu:languageChanged') + langText;
			const languageUpdatedText = i18n.t('menu:languageUpdatedMessage');
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
					<span>{languageText}</span>
				</span>,
				languageUpdatedText,
			);
		});
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
	});

	const handleUpcomingSupport = () => {
		setOffcanvasStatus(!offcanvasStatus);
	};

	return (
		<>
			{' '}
			{mobileDesign ? (
				<></>
			) : (
				<HeaderRight>
					<div className='row g-3'>
						{/* Dark Mode */}
						<div className='col-auto'>
							<Popovers trigger='hover' desc='Dark / Light mode'>
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									onClick={() => setDarkModeStatus(!darkModeStatus)}
									className='btn-only-icon'
									data-tour='dark-mode'
									aria-label='Toggle dark mode'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='btn-icon'
									/>
								</Button>
							</Popovers>
						</div>

						{/* Suporte Selector */}
						<div className='col-auto position-relative'>
							<Popovers trigger='hover' desc={t('general:need_help:support')}>
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									icon='Support Agent'
									className={`${darkModeStatus ? 'text-light' : 'text-navy'}`}
									onClick={() => handleUpcomingSupport()}
									aria-label='Support'
								/>
							</Popovers>
							<Icon
								icon='Circle'
								className={classNames(
									'position-absolute start-65',
									'text-success',
									'animate__animated animate__heartBeat animate__infinite animate__slower',
								)}
							/>
						</div>

						{/*	Full Screen */}
						<div className='col-auto'>
							<Popovers trigger='hover' desc='Fullscreen'>
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
									onClick={() => setFullScreenStatus(!fullScreenStatus)}
									aria-label='Toggle fullscreen'
								/>
							</Popovers>
						</div>

						{/* Lang Selector */}
						<div className='col-auto'>
							<Dropdown>
								<DropdownToggle hasIcon={false}>
									{typeof getLangWithKey(i18n.language as ILang['key']['lng'])
										?.icon === 'undefined' ? (
										<Button
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...styledBtn}
											className='btn-only-icon'
											aria-label='Change language'
											data-tour='lang-selector'>
											<Spinner isSmall inButton='onlyIcon' isGrow />
										</Button>
									) : (
										<Button
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...styledBtn}
											icon={
												getLangWithKey(i18n.language as ILang['key']['lng'])
													?.icon
											}
											aria-label='Change language'
											data-tour='lang-selector'
										/>
									)}
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
									{Object.keys(LANG).map((i) => (
										<DropdownItem key={LANG[i].lng}>
											<Button
												icon={LANG[i].icon}
												onClick={() => changeLanguage(LANG[i].lng)}>
												{LANG[i].text}
											</Button>
										</DropdownItem>
									))}
								</DropdownMenu>
							</Dropdown>
						</div>

						<div className='col-auto'>
							<CommonHeaderUser />
						</div>
					</div>
					<CommonSupportOffCanvas setOpen={setOffcanvasStatus} isOpen={offcanvasStatus} />
				</HeaderRight>
			)}
		</>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
