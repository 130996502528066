export type RolesPagesAllowed = {
	financial: string[];
	manager: string[];
	technical: string[];
};

const rolesPagesAllowed: RolesPagesAllowed = {
	financial: ['billing'],
	manager: ['customerServices', 'billing', 'reports', 'registry'],
	technical: ['customerServices', 'reports'],
};

export const rolesDashboardAllowed: RolesPagesAllowed = {
	financial: ['dashboard'],
	manager: ['dashboard'],
	technical: ['dashboard'],
};

export const rolesContentAllowed: RolesPagesAllowed = {
	financial: ['dashboard', 'billing', 'profile'],
	manager: ['dashboard', 'customerServices', 'billing', 'reports', 'registry', 'profile'],
	technical: ['dashboard', 'customerServices', 'reports', 'profile'],
};

export default rolesPagesAllowed;
