import { lazy } from 'react';
import { appPagesMenu, dashboardPagesMenu, profilePage } from '../menu';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};

const APP = {
	REPORTS: lazy(() => import('../pages/presentation/reports/ReportsDashboard')),
	BILLING: lazy(() => import('../pages/presentation/billing/BillingPage')),
	REGISTRY: lazy(() => import('../pages/presentation/users/UsersTable')),
	PROFILE: lazy(() => import('../pages/presentation/profile/EmployeePage')),
	CUSTOMER_SERVICES: lazy(
		() => import('../pages/presentation/customer-services/CustomerServicesPage'),
	),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		id: 'dashboard',
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},

	/**
	 * App
	 */
	{
		id: 'customerServices',
		path: appPagesMenu.customerServices.path,
		element: <APP.CUSTOMER_SERVICES />,
	},
	{
		id: 'reports',
		path: appPagesMenu.reports.path,
		element: <APP.REPORTS />,
	},
	{
		id: 'billing',
		path: appPagesMenu.billing.path,
		element: <APP.BILLING />,
	},
	{
		id: 'registry',
		path: appPagesMenu.registry.path,
		element: <APP.REGISTRY />,
	},
	{
		id: 'profile',
		path: profilePage.profile.path,
		element: <APP.PROFILE />,
	},
];

const contents = [...presentation];

export default contents;
